.button {
  background-color: inherit;
}

.primary {
  background: #0071e3;
  color: var(--text);
  font-size: 1rem;
  line-height: 1rem;
  border-radius: 4px;
  transition: all 0.2s ease-in-out 0s;
  box-sizing: border-box;
  padding: 1rem;
}

.primary:disabled {
  background-color: grey;
  cursor: not-allowed;
}
