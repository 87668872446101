.form {
  position: relative;
  background: var(--bgcolor2);
  border-radius: 4px;
  padding: 1rem 0.5rem;
}

.closeButtonContainer {
  position: absolute;
  padding: 1rem;
  right: 0;
  top: 0;
}

.submitButtonContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.submitStatus {
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  font-weight: 700;
}
.submitStatus.error {
  color: red;
}
.submitStatus.success {
  color: green;
}

.form > * {
  margin-bottom: 0.5rem;
}
