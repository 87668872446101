.container {
  padding: 1rem;
  .h4 {
    text-align: center;
    color: #fff;
  }

  .PaymentType {
    padding: 1rem;
    display: flex;
    justify-content: center;
  }
}
