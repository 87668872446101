.button {
  display: block;
  position: relative;
  background-color: inherit;
  width: 1.5rem;
  height: 1.5rem;
}

.button::before,
.button::after {
  position: absolute;
  display: block;
  content: '';
  width: 100%;
  height: 2px;
  background-color: var(--text);
  top: 50%;
  transform: translateY(-50%);
}

.button::before {
  transform: translateY(-50%) rotate(45deg);
}
.button::after {
  transform: translateY(-50%) rotate(-45deg);
}
