.checkboxGroup {
  position: relative;
  padding: 0.5rem 0;
  display: flex;
  gap: 1rem;
}
.checkboxGroup input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}

.checkboxGroup input:checked + .checkboxSwitchLable {
  background: #0071e3 !important;
}
.checkboxGroup input:checked + .checkboxSwitchLable::before {
  left: 24px;
}
.checkboxGroup .checkboxSwitchLable {
  width: 44px;
  height: 22px;
  border-radius: 100px;
  display: block;
  position: relative;
  background: var(--bgcolor);
  background-color: rgba(10, 28, 61);
}
html[data-theme='light'] .checkboxGroup .checkboxSwitchLable {
  background: #dfdfdf;
}
.checkboxGroup .checkboxSwitchLable::before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -moz-ransform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  content: '';
  left: 4px;
  width: 16px;
  height: 16px;
  -webkit-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  background: #fff;
  border-radius: 50%;
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2),
    inset 0px 1px 2px rgba(255, 255, 255, 0.1),
    inset 0px -1px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2),
    inset 0px 1px 2px rgba(255, 255, 255, 0.1),
    inset 0px -1px 1px rgba(0, 0, 0, 0.1);
}
