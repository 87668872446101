.container {
  position: relative;
  padding: 1rem 0;
  color: var(--text);
}

.showAllButtonContainer {
  position: absolute;
  top: 1rem;
  right: 0;
  z-index: 1;
  padding: 0 1rem;
}

.noInfo,
.error {
  padding: 0.5rem 0;
}

.error {
  color: red;
}

.showAllButton {
  display: inline-block;
  background-color: inherit;
  color: inherit;
  color: var(--text2);
  font-size: 0.8rem;
  border-bottom: 1px dotted var(--text2);
}

.buttonContainer {
  text-align: center;
  padding: 0.5rem 0;
}
