.BillingInfoElement {
  color: var(--text);
  position: relative;
  margin-bottom: 10px;
  background: var(--bgcolor2);
  border-radius: 10px;
  padding: 1rem;
  font-size: 0.7rem;
}

.BillingInfoElement:has(.BillingInfoElementInput:checked) {
  background-color: rgba(10, 28, 61);
}

.BillingInfoElementInput {
  position: absolute;
  width: 0;
  height: 0;
  z-index: -1;
}

.BillingInfoElementInputLabel,
.BillingInfoElementLabel {
  position: relative;
  font-size: inherit;
  display: block;
  transition: all 0.2s ease-in-out 0s;
  cursor: pointer;
}

.BillingInfoElementInputLabel {
  padding-left: 3rem;
}

.BillingInfoElementInputLabel::before,
.BillingInfoElementInputLabel::after {
  position: absolute;
  content: '';
  transition: all 0.2s ease-in-out 0s;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
}

.BillingInfoElementInputLabel::before {
  width: 18px;
  height: 18px;
  left: 0;
  border: 1px solid #e6e7eb;
}

.BillingInfoElementInputLabel::after {
  width: 10px;
  height: 10px;
  left: 5px;
  background: #0071e3;
  opacity: 0;
}

.BillingInfoElement
  .BillingInfoElementInput:checked
  + .BillingInfoElementInputLabel::before {
  border: 1px solid #0071e3;
}
.BillingInfoElement
  .BillingInfoElementInput:checked
  + .BillingInfoElementInputLabel::after {
  opacity: 1;
}
.BillingInfoElement .BillingInfoElementInput:disabled {
  cursor: not-allowed;
}

.BillingInfoElement
  .BillingInfoElementInput:disabled
  + .BillingInfoElementInputLabel::before {
  border-color: lightgray;
}
.BillingInfoElement
  .BillingInfoElementInput:disabled
  + .BillingInfoElementInputLabel::after {
  background-color: lightgray;
}

.BillingInfoElementDetails {
  position: relative;
  padding-left: 2rem;
  font-size: inherit;
}

.BillingInfoElementDetails {
  position: relative;
  padding-left: 3rem;
  cursor: pointer;
}

.BillingInfoElementDetails summary {
  top: -1.5rem;
  left: 2rem;
  position: absolute;
}

.BillingInfoDetailsElement {
  display: flex;
}

.BillingInfoDetailsElementTitle {
  display: inline-block;
  width: 5rem;
}

.BillingInfoDetailsElementData {
  color: var(--text);
  display: inline-block;
  line-break: anywhere;
}
