.inputGroup {
  position: relative;
  background-color: inherit;
  font-family: 'Plus Jakarta Sans';
}

.input {
  font-family: inherit;
  font-size: 14px;
  line-height: 16px;
  padding: 1rem 1.5rem;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  color: var(--text);
}

.input:disabled {
  cursor: not-allowed;
  color: var(--text2);
}

.label {
  display: block;
  text-align: left;
  font-family: inherit;
  position: absolute;
  left: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  color: var(--text2);
  transition: all 0.2s;
}
.input::placeholder {
  visibility: hidden;
}

.input:focus + .label,
.input:not(:placeholder-shown) + .label {
  top: -3px;
  transform: scale(0.6);
  transform-origin: left;
}
